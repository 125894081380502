/* eslint-disable jsx-a11y/anchor-is-valid */
import { Images } from "../../core";
import "./Hero.css"

function Hero() {
    return (
        <section className="section">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="p-3 p-lg-4 d-flex justify-content-center align-items-center">
                            <div className="p-3 p-lg-4">
                                <h1 className="display-5 mb-5">Bilişimde Güvenilir Çözüm Ortağınız.</h1>
                                <p>İhtiyacınıza uygun altyapı çözümleri, iş süreçlerinizi optimize eden danışmanlık hizmetleri ve doğru teknoloji seçimleriyle işletmenizi bir adım öteye taşıyoruz.</p>
                                <a href="#" className="mt-5 btn btn-md btn-outline-dark">Hizmetlerimizi Keşfedin</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 offset-1 px-3 mb-4 d-flex justify-content-center align-items-center">
                        <div>
                            <img src={Images.HeroImg} className="img img-fluid rounded-4" alt="Ilgın Bilgisayar" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;