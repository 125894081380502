/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Cta2() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="row shadow shadow-lg p-4 rounded rounded-5">
                        <div className="bg-success bg-opacity-50 d-flex-center-y mb-3 rounded rounded-5 ps-4 w-50">
                            <p className="bi bi-bell-fill text-white fs-3 p-0 m-0 py-3"></p>
                        </div>
                        <div className="col-lg-8 px-3 d-flex-center-y">
                            <h1 className="display-6 my-4">Bilişimde Güvenilir Çözüm Ortağınız</h1>
                         <p>
                         Sorunları çözmek ve fırsatları değerlendirmek için yaratıcı düşünceyi teşvik ediyoruz.
                         </p>
                        </div>
                        <div className="col-lg-4 px-3 d-flex-center-y">
                            <a href="#" className="btn btn-lg btn-outline-dark">
                                <span>Bizi Arayın</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
