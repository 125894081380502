/* eslint-disable jsx-a11y/anchor-is-valid */
import { SectionTitle } from "../../core";
import "./Solutions.css"

function Solutions() {
    return (
        <section className="section">
            <div className="container">
                <SectionTitle titleAlignment="text-center" render="center" title="Hizmetlerimiz" slogan="Hızlı servis ve destek hizmetlerimizle yanınızdayız" />
                <div className="row">
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Altyapı Hizmetleri</h5>
                            <p>
                                Altyapı kurulum hizmetleri ile işletmenizin teknolojik temelini güçlendiriyoruz. Yüksek performanslı ağlar, güvenilir sunucular ve son teknoloji donanımlar ile iş sürekliliğinizi sağlıyor ve rekabette öne çıkmanızı destekliyoruz. Altyapınızı kurmak, yönetmek ve güncellemek için profesyonel ekibimiz her zaman hazır.
                            </p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Sunucu Hizmetleri</h5>
                            <p>Sistem hizmetlerimizle işletmenizin verimliliğini ve güvenilirliğini artırıyoruz. İşletmenizin temel taşı olan teknolojik altyapıyı en iyi şekilde yönetiyor, sorunsuz işleyiş sağlıyor ve sizi rekabette bir adım öne çıkarıyoruz. Her türlü sistem ihtiyacınız için çözüm sunuyoruz ve işletmenizi geleceğe taşıyoruz.</p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Güvenlik Hizmetleri</h5>
                            <p>
                                Güvenlik hizmetleri ile işletmenizi ve varlıklarınızı tehditlere karşı koruyoruz. Profesyonel donanım ve son teknoloji çözümlerimizle, güvenliği en üst seviyeye çıkarıyoruz. Fiziksel güvenlikten siber güvenliğe kadar geniş bir yelpazede hizmet sunuyor, işletmenizi kesintisiz bir şekilde koruyoruz.
                            </p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Yedekleme Hizmetleri</h5>
                            <p>
                                Yedekleme hizmetlerimizle verileriniz her zaman güvende. İşletmeniz için kritik öneme sahip olan verileri, son teknoloji yedekleme çözümlerimizle güvenli bir şekilde saklıyoruz. Veri kaybı endişesi olmadan işinize odaklanabilirsiniz.
                            </p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Lisanslama Hizmetleri</h5>
                            <p>
                                Lisanlama hizmetlerimizle yazılım ve ürün lisanslarınızı sorunsuz bir şekilde yönetiyoruz. Karmaşık lisanslama süreçlerini sizin için basit ve anlaşılır hale getiriyor, yasal gerekliliklere uygunluğunuzu sağlıyoruz. Size lisanslarınızın tam kontrolünü ve güvencesini sunarak, işinize odaklanmanızı kolaylaştırıyoruz.
                            </p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-5">
                        <div className="solution-item">
                            <h5 className="fs-4">Yazılım Hizmetleri</h5>
                            <p>
                                .NET yazılım ve web uygulama geliştirme hizmetleriyle işletmenizin dijital dönüşümünü hızlandırıyoruz. Özelleştirilmiş ve yenilikçi web uygulamaları ile iş süreçlerinizi optimize eden çözümler sunuyoruz. Sizin için en uygun teknolojik çözümleri tasarlayarak işletmenizi rekabetin önünde tutuyoruz.
                            </p>
                            {/* <a href="#" className="btn btn-md btn-outline-secondary w-25">Submit</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Solutions;