import { SectionTitle } from "../../core";
import "./About.css"

function About() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <SectionTitle titleAlignment="text-left" render="" title="Hakkımızda" slogan="ILGIN Bilgisayar" />
                    <div className="row mb-5">
                        <div className="col-lg-3 px-3 mb-4">
                            <div className="about-item">
                                <h4>Hızlı servis ve destek hizmetlerimizle yanınızdayız.</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 px-3 mb-4">
                            <div className="about-item">
                                <h4>Uzman ekibimizle yıllardır sektörün öncüsüyüz.</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 px-3 mb-4">
                            <div className="about-item">
                                <h4>Kaliteyi uygun fiyatlarla buluşturuyoruz.</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 px-3 mb-4">
                            <div className="about-item">
                                <h4>Sürekli gelişen teknolojileri takip ediyor, işletmenize özel çözümler üretiyoruz.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column gap-4">
                            <h1 className="display-1">Neden Bizi Tercih Etmelisiniz?</h1>
                            <p className="lead">
                                20 yılı aşkın deneyimimizle bilişim sektöründe hizmet veren lider şirketlerden biriyiz. Güvenilir ve yenilikçi çözümlerimizle bireysel ve kurumsal müşterilerimize değer katmayı hedefliyoruz. Müşteri memnuniyeti, kalite ve sürdürülebilirlik odaklı yaklaşımımızla iş ortaklarımız için fark yaratıyoruz.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;