/* eslint-disable jsx-a11y/anchor-is-valid */
function Cta() {
    return (
        <section className="section-bg">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 px-3 mb-4">
                            <h2 className="display-5 mb-4">
                                Sistem Danışmanlık Hizmetleri
                            </h2>
                            <p className="lead">Bilgi teknolojisi altyapınızın sorunsuz çalışmasını sağlamak için düzenli bakım ve hızlı servis çözümleri sunuyoruz.</p>
                        </div>
                        <div className="col-lg-4 px-3 mb-4 d-flex-center">
                            <a href="#" className="btn btn-lg btn-outline-primary">Bizimle İletişime Geçin!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Cta;