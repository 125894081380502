import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Navbar, Parallax, Footer, Cta, Slider, Cta2 } from "./core"
import { Contact, Hero, Solutions, About } from "./pages"
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Navbar />
    <Hero />
    <Cta />
    <Solutions />
    <Parallax />
    <About />
    <Contact />
    <Cta2/>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
