/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Footer.css"

function Footer() {
    return (
        <div className="container-fluid bg-dark text-white py-5">
            <div className="container">
                <div className="row py-5">
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="mb-4">
                            <h5 className="h-5">IlGIN Bilgisayar</h5>
                        </div>
                        <div className="d-flex-center-x flex-column gap-3">
                            <p>
                                20 yılı aşkın deneyimimizle bilişim sektöründe hizmet veren lider şirketlerden biriyiz. Güvenilir ve yenilikçi çözümlerimizle bireysel ve kurumsal müşterilerimize değer katmayı hedefliyoruz.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="mb-4">
                            <h5 className="text-center h-5">Site Haritası</h5>
                        </div>
                        <div className="d-flex-center-x">
                            <ul className="list-unstyled d-flex gap-3 flex-column">
                                <li>
                                    <a href="#" className="">Ana Sayfa</a>
                                </li>
                                <li>
                                    <a href="#" className="">Hakkımızda</a>
                                </li>
                                <li>
                                    <a href="#" className="">Sistem </a>
                                </li>
                                <li>
                                    <a href="#" className="">Altyapı </a>
                                </li>
                                <li>
                                    <a href="#" className="">Lisanslama </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 px-3 mb-4">
                        <div className="mb-4">
                            <h5 className="text-center h-5">Bilgi Bankası</h5>
                        </div>
                        <div className="d-flex-center-x">
                            <ul className="list-unstyled d-flex gap-3 flex-column">
                                <li>
                                    <a href="#" className="">KVKK</a>
                                </li>
                                <li>
                                    <a href="#" className="">Çerez Politikası</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <ul className="list-unstyled d-flex gap-3">
                            <li>
                                <a href="tel:+905326039750" className="social-icons">
                                    <i className="bi bi-whatsapp"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/ilginbilgisayar?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" className="social-icons">
                                    <i className="bi bi-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:i.goktas@ilginbilgisayar.com" className="social-icons">
                                    <i className="bi bi-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;