import "./Parallax.css"

function Parallax() {
    return (
        <div className="parallax">
            <div className="parallax-caption"></div>
        </div>
    );
}

export default Parallax;