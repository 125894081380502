import React from "react";
import "./Contact.css";

export const Contact = () => {
    return (
        <div className="container py-5 my-5">
            <div className="row py-5 d-flex justify-content-center align-items-center">
                <div className="col-lg-5 px-3 mb-5 d-flex justify-content-center align-items-center">
                    <div>
                        <h1 className="display-4 py-3">Kaliteyi uygun fiyatlarla buluşturuyoruz.</h1>
                        <p className="lead">
                        Altyapı projelerinizin planlama, kurulum ve teslim süreçlerini titizlikle yönetiyoruz. İşinizi kolaylaştıran anahtar teslim çözümler sunuyoruz.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-1 px-3 mb-4 d-flex flex-column justify-content-center py-5 border shadow">
                    <div className="p-1 px-4">
                        <div className="d-flex justify-content-between align-items-center bg-secondary bg-opacity-10 p-lg-4 p-2 rounded rounded-3">
                            <h1 className="display-5">İletişim Formu</h1>
                            <i className="bi bi-envelope-fill fs-1"></i>
                        </div>
                        <div className="w-100 py-5 d-flex gap-4 flex-column">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="txtName"
                                    placeholder="Adınız"
                                />
                                <label htmlFor="txtName">Adınız</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="txtSurname"
                                    placeholder="Soyadınız"
                                />
                                <label htmlFor="txtSurname">Soyadınız</label>
                            </div>
                            <div className="form-floating">
                                <textarea
                                    className="form-control"
                                    style={{ height: 240 }}
                                    id="txtMessage"
                                    placeholder="İletiniz"
                                ></textarea>
                                <label htmlFor="txtMessage">İletiniz</label>
                            </div>
                            <button className="btn btn-lg btn-outline-dark w-50">
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
