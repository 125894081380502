/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Navbar.css"

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg bg-secondary bg-opacity-10 py-lg-4 py-3">
            <div className="container">
                <a className="navbar-brand fs-3" href="#">ILGIN</a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav mx-auto d-flex gap-lg-4 gap-2 mb-3 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" href="#">
                                Ana Sayfa
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Hakkımızda
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Hizmetler
                            </a>
                        </li>
                        <li className="nav-item dropdown test">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                            >
                                Bilgi Bankası
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        KVKK
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Çerez Politikası
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <a href="#" className="btn btn-md btn-outline-dark d-none d-lg-block">İletişim</a>
            </div>
        </nav>


    );
}

export default Navbar;