import { useEffect, useState } from "react";

interface I_Props {
    titleAlignment: string
    render: string
    title: string
    slogan: string
}

export const SectionTitle: React.FC<I_Props> = ({ titleAlignment, render, title,slogan }) => {
    const [alignment, setAlignment] = useState("")

    useEffect(() => {
        setAlignment(render)

    }, [render])


    if (alignment === "center") {
        return (
            <div className="container">
                <div className="row mb-5 d-flex-center">
                    <div className={`col-lg-7 ${titleAlignment}`}>
                        <h1 className={`display-3`}>{title}</h1>
                        <p className="lead">{slogan}</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-7">
                    <h1 className={`display-3 ${titleAlignment}`}>{title}</h1>
                    <p className="lead">{slogan}</p>
                </div>
            </div>
        </div>
    );
}
